// import React from "react";
import { LayoutComponent, Link, Outlet } from "rasengan";
import Image from "@rasenganjs/image";
import Logo from "@assets/images/logo.svg";
import Button from "@components/atoms/Button";

const AppLayout: LayoutComponent = () => {
  return (
    <section className="w-full px-[20px] md:px-[50px] xl:px-[200px] ">
      <header className="flex justify-between items-center w-full h-[80px] mt-0 fixed top-0 left-0 px-[20px] md:px-[50px] xl:px-[200px] z-50 bg-[#F5F5F5] inset-0 bg-cover bg-center backdrop-filter bg-opacity-50 backdrop-blur-md">
        <Link to="/" className="font-bold text-lg">
          <Image 
            src={Logo}
            alt="D3 art logo"
            width={100}
            height={"auto"}
            loading="lazy"
          />
        </Link>

        <nav className="flex items-center gap-2">
          <ul className="flex items-center gap-4 font-urbanistBold">
            <li>
              <Link to="/#skills">Skills</Link>
            </li>
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
          </ul>

          <Button text="Contact" className="bg-primary text-white ml-8" />
        </nav>
      </header>
      
      <main className="mt-[80px]">
        <Outlet />
      </main>
    </section>
  );
}

AppLayout.path = "/";

export default AppLayout;
