import { defineConfig } from 'rasengan';

export default defineConfig({
  reactStrictMode: true,

  server: {
    development: {
      port: 3000
    },

    production: {
      hosting: "vercel"
    }
  },

  // Define aliases
  vite: {
    resolve: {
      alias: [
        {
          find: "@app",
          replacement: "./src/app"
        },
        {
          find: "@components",
          replacement: "./src/components"
        },
        {
          find: "@assets",
          replacement: "./src/assets"
        },
        {
          find: "@styles",
          replacement: "./src/styles"
        },
        {
          find: "@data",
          replacement: "./src/data"
        }
      ]
    },

    optimizeDeps: {
      include: ["@rasenganjs/image"]
    },

    build: {
      rollupOptions: {
        external: ["@rasenganjs/image"]
      }
    }
  }
});