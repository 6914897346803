import React from "react";
import { twMerge } from "tailwind-merge";
type TechItemProps = {
  text: string;
  className?: string;
  source?: string;
  textStyle?: string;
  imgStyle?: string;
};

export default function SkillItem({
  text,
  className,
  source,
  textStyle,
  imgStyle,
}: TechItemProps) {
  // local state
  const [isHovered, setIsHovered] = React.useState(false);

  // Handlers
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  return (
    <div
      className={twMerge(
        "flex items-center justify-center px-4 bg-white rounded-md shadow-lg hover:shadow-xl hover:scale-110 transition-all duration-300 cursor-pointer",
        className,
        source ? "py-0 pl-0" : "py-4"
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {source && (
        <img
          src={source}
          alt=""
          className={twMerge(
            "w-12 mr-2 transition-all duration-300",
            isHovered ? "rotate-360" : "-rotate-360",
            imgStyle
          )}
        />
      )}
      <span className={twMerge("text-sm font-latoBold capitalize", textStyle)}>
        {text}
      </span>
    </div>
  );
}
