import Image1 from "@assets/images/digital-painting/1.png";
import Image2 from "@assets/images/digital-painting/2.png";
import Image3 from "@assets/images/digital-painting/3.png";
import Image4 from "@assets/images/digital-painting/4.png";
import { DataType } from "..";

const data: DataType[] = [
  {
    code: "painting1",
    image: Image1,
    description: "A digital painting of John",
    title: "John",
    tags: ["digital-art", "painting", "drawing"],
  },
  {
    code: "painting2",
    image: Image2,
    description: "A digital painting of Sophie",
    title: "Sophie",
    tags: ["digital-art", "painting", "drawing"],
  },
  {
    code: "painting3",
    image: Image3,
    description: "A digital painting of Romeo",
    title: "Romeo",
    tags: ["digital-art", "painting", "drawing"],
  },
  {
    code: "painting4",
    image: Image4,
    description: "A digital painting of a Mia",
    title: "Mia",
    tags: ["digital-art", "painting", "drawing"],
  },
]

export default data;
