import SkillItem from "@components/molecules/SkillItem";

// Import images
import blender from "@assets/images/skills/blender.png";
import figma from "@assets/images/skills/figma.png";
import inkscape from "@assets/images/skills/inkscape.png";
import krita from "@assets/images/skills/krita.png";
import javascript from "@assets/images/skills/js.png";

export default function SkillGroup() {
  return (
    <div className="w-full flex flex-wrap gap-2 mt-8 justify-center md:justify-start">
      <SkillItem text="Blender" source={blender} className="font-urbanistBold" />
      <SkillItem text="Figma" source={figma} className="font-urbanistBold" />
      <SkillItem text="Inkscape" source={inkscape} className="font-urbanistBold" />
      <SkillItem text="Krita" source={krita} className="font-urbanistBold" />
      <SkillItem text="Javascript" source={javascript} className="font-urbanistBold" />
    </div>
  )
}