import _3D1 from "@assets/images/3D/1.png";
import _3D2 from "@assets/images/3D/2.png";
import _3D3 from "@assets/images/3D/3.png";
import { DataType } from "..";

const data: DataType[] = [
  {
    code: "3D1",
    image: _3D1,
    description: "A 3D model of gifts",
    title: "Car",
    tags: ["3D", "model"],
  },
  {
    code: "3D2",
    image: _3D2,
    description: "A 3D model of a painting",
    title: "House",
    tags: ["3D", "model", "painting", "drawing"],
  },
  {
    code: "3D3",
    image: _3D3,
    description: "A 3D model of phones",
    title: "Tree",
    tags: ["3D", "model"],
  },
];

export default data;
