import { DataType } from "@/data";
import Image from "@rasenganjs/image";
import React from "react";

type Props = {
  data: DataType[];
};

export default function ImageGroup({ data }: Props) {
  // State
  const [active, setActive] = React.useState(0);
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);

  // Effects
  React.useEffect(() => {
    // Call resize function
    handleResize();

    // When an event is listened
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handlers
  const handleSetActive = (index: number) => {
    setActive(index);
  };

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  return (
    <div className="flex gap-2 transition-all duration-200 w-full lg:w-[50%] justify-center">
      {data.map(({ image, description }, index: number) => (
        <div
          key={index}
          className="transition-all durantion-200"
          onMouseEnter={() => handleSetActive(index)}
          style={{
            width:
              active === index
                ? isSmallScreen
                  ? "70%"
                  : 300
                : isSmallScreen
                ? "17%"
                : 80,
          }}
        >
          <Image
            src={image}
            alt={description}
            width={
              active === index
                ? isSmallScreen
                  ? "100%"
                  : 300
                : isSmallScreen
                ? "100%"
                : 80
            }
            height={400}
            loading="lazy"
            className="transition-all durantion-200 rounded-lg "
          />
        </div>
      ))}
    </div>
  );
}
