// Import images
import logo1 from "@assets/images/logos-design/1.svg";
import logo2 from "@assets/images/logos-design/2.svg";
import logo3 from "@assets/images/logos-design/3.svg";
import { DataType } from "..";

const data: DataType[] = [
  {
    code: "logo1",
    image: logo1,
    description: "Personal Logo of Me",
    title: "D3 Logo",
    tags: ["logo", "design"],
  },
  {
    code: "logo2",
    image: logo2,
    description: "Personal Logo of Me",
    title: "D3 Art Logo",
    tags: ["logo", "design"],
  },
  {
    code: "logo3",
    image: logo3,
    description: "Logo for my React Framework, Rasengan.",
    title: "Rasengan Logo",
    tags: ["logo", "design"],
  },
]

export default data;
