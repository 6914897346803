import Button from "@components/atoms/Button";
import ImageGroup from "@components/molecules/ImageGroup";
import { type PageComponent } from "rasengan";
import Image from "@rasenganjs/image";

// Import images
import HeroImage from "@assets/images/hero.png";
import SkillImage from "@assets/images/skill.png";
import SkillGroup from "@components/molecules/SkillGroup";

// Data
import logoData from "@data/logo";
import paintingData from '@data/painting';
import _3DData from '@data/3D';

const Home: PageComponent = () => {
  return (
    <section className="w-full h-full flex flex-col items-center">
      {/* Hero section */}
      <section className="w-full h-auto md:h-[90vh] py-8 md:py-0 flex flex-col-reverse md:flex-row justify-between items-center">
        <div className="w-full md:w-[50%] flex flex-col items-center md:items-start">
          <h3 className="text-[1.5em] font-urbanistBold text-center">
            Dilane Kombou, D3
          </h3>
          <h1 className="font-technoRace text-[3em] md:text-[4em] text-primary text-center md:text-start">
            Digital Artist
          </h1>
          <p className="w-full text-center md:text-start">
            I'm a Character Designer based in Cameroon. I love to create
            characters and tell stories with them. Also 3D modeling, games and
            animation are my passion.
          </p>

          <div className="flex items-center gap-2 mt-8">
            <Button text="View Gallery" className="bg-primary text-white" />
          </div>
        </div>

        <div className="mb-4 md:mb-0">
          <Image 
            src={HeroImage}
            alt="Hero Image"
            width={350}
            height={"90vh"}
            objectfit="contain"
            loading="lazy"
            mode="wave"
          />
        </div>
      </section>

      {/* Skills section */}
      <section className="w-full h-auto md:h-[100vh] py-8 md:py-0 flex flex-col-reverse md:flex-row justify-between items-center">
        <div className="w-full md:w-[50%] flex flex-col items-center md:items-start">
          <h1 className="font-technoRace text-[3em] md:text-[4em] text-primary text-center">
            Skills
          </h1>
          <p className="text-center  md:text-start">
            Here is the list of tools that I used frequently.
          </p>

          <SkillGroup />
        </div>

        <div>
          <Image 
            src={SkillImage}
            alt="Skill Image"
            width={350}
            height={"90vh"}
            objectfit="contain"
          />
        </div>
      </section>

      {/* Digital painting */}
      <section className="w-full h-auto lg:h-[100vh] py-8 lg:py-0 flex flex-col-reverse lg:flex-row justify-between items-center gap-4">
        <div className="w-full lg:w-[50%] flex flex-col items-center lg:items-start">
          <h1 className="font-technoRace text-[3em] md:text-[4em] text-primary text-center">
            Digital Painting
          </h1>
          <p className="text-center lg:text-start">
            I did a lot of digital drawing in many styles and also some
            portraits.
          </p>

          <div className="mt-8">
            <Button text="See more" className="bg-primary text-white" />
          </div>
        </div>

        <ImageGroup data={paintingData.slice(0, 3)} />
      </section>

      {/* Logo Design */}
      <section className="w-full h-auto lg:h-[100vh] py-8 lg:py-0 flex flex-col-reverse lg:flex-row-reverse justify-between items-center gap-4">
        <div className="w-full lg:w-[50%] flex flex-col items-center lg:items-end">
          <h1 className="font-technoRace text-[3em] md:text-[4em] text-primary text-center">
            Logo Design
          </h1>
          <p className="text-center lg:text-end">
            About logos, I did some for my personal projects and my own logo.
          </p>

          <div className="mt-8">
            <Button text="See more" className="bg-primary text-white" />
          </div>
        </div>

        <ImageGroup data={logoData.slice(0, 3)} />
      </section>

      {/* UI Design */}
      <section className="w-full h-auto lg:h-[100vh] py-8 lg:py-0 flex flex-col-reverse lg:flex-row items-center gap-4">
        <div className="w-full lg:w-[50%] flex flex-col items-center lg:items-start">
          <h1 className="font-technoRace text-[3em] md:text-[4em] text-primary text-center">
            UI Design
          </h1>
          <p className="text-center  lg:text-start">
            Before starting to code, I like to design the UI of my projects. I
            have done some for my personal projects and for my clients.
          </p>

          <div className="mt-8">
            <Button text="See more" className="bg-primary text-white" />
          </div>
        </div>

        <ImageGroup data={paintingData.slice(0, 3)} />
      </section>

      {/* 3D Modeling */}
      <section className="w-full h-auto lg:h-[100vh] py-8 lg:py-0 flex flex-col-reverse lg:flex-row-reverse justify-between items-center gap-4">
        <div className="w-full lg:w-[50%] flex flex-col items-center lg:items-end">
          <h1 className="font-technoRace text-[3em] md:text-[4em] text-primary text-center lg:text-end">
            3D Modeling & Animations
          </h1>
          <p className="text-center lg:text-end">
            I'm a 3D artist, I like model 3D objects and animate them. I have
            done some for my personal projects.
          </p>

          <div className="mt-8">
            <Button text="See more" className="bg-primary text-white" />
          </div>
        </div>

        <ImageGroup data={_3DData.slice(0, 3)} />
      </section>
    </section>
  );
}

Home.path = "/";
Home.metadata = {
  title: "Home",
  description: "Artistic portfolio of Dilane Kombou, Digital Artist.",
}



export default Home;
