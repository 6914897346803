import React from "react";

type Props = {
  text: string;
  className?: React.ComponentProps<"button">["className"];
}

export default function Button({ text, className }: Props) {
  return (
    <button className={`rounded-full px-4 py-2 font-urbanistBold ${className}`}>{text}</button>
  )
}